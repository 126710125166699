﻿/*Css font*/
@font-face {
    font-family: Roboto-Regular;
    src: url('../assets/Roboto/Roboto-Regular.ttf')
}

@font-face {
    font-family: Roboto-Bold;
    src: url('../assets/Roboto/Roboto-Bold.ttf')
}
/*end css font*/

/* css login*/
.login-page {
    background: -webkit-linear-gradient(bottom, #0250c5, #30a99d);
}
/* css login public*/
.login-page-public {
    background: -webkit-linear-gradient(bottom, #0250c5, #30a99d);
}

.login-page .login-logo b {
    color: #fff
}
/*fix bug can giua header name*/
table {
    th {
        text-align: center;
    }
}

/*doi mau modal header*/
.modal-dialog {
    .modal-header {
        background: #17a2b8;
    }
}
/*doi mau modal title*/
.modal-dialog {
    .modal-header {
        color: white;
    }
}
/*doi co chu header title modal*/
.modal-dialog {
    .modal-header {
        .h4 {
            font-size: 1.2rem;
        }
    }
}

.titleNavbar {
    font-family: Roboto-Bold;
    font-size: 21px
}

.wrapper {
    .main-sidebar.sidebar-dark-primary.elevation-4 {
        background-color: #024f5a;

        .brand-link {
            background-color: #024f5a;
        }
    }
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
    background-color: #c79602;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
    background-color: unset;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active, .sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
    background-color: #17a2b8;
}

.nav-link.active {
    background-color: #c79602;
}

.sidebar-mini {
    .sidebar {
        .nav-item {
            .nav-link {
                display: flex;
                align-items: center;
                white-space: pre-line;
            }

            .nav-icon {
                max-width: 1.6rem;
                min-width: 1.6rem;
            }
        }
    }

    &.sidebar-collapse {
        .main-sidebar {
            .sidebar .nav-item .nav-link p {
                display: none;
            }

            &:hover {
                .sidebar .nav-item .nav-link p {
                    display: inline-block;
                    width: auto;
                }
            }
        }
    }
}
/*.nav-sidebar > li > ul > li > a {
    padding-left: 30px !important;
}*/
.scrollbar.scrollbar-dark::-webkit-scrollbar {
    width: 5px;
    background-color: #17a2b8;
}

.scrollbar.scrollbar-dark::-webkit-scrollbar-thumb {
    background-color: #17a2b8;
}

.notification {
    bottom: 10px;
    right: 10px;
    position: absolute;
}

.notification.alert-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.notification.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.notification.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.toast-alert {
    bottom: -5px;
    right: 10px;
    position: absolute;
    z-index: 9999;
}

.toast-alert.primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.toast-alert.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.toast-alert.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.toast-alert.info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.toast-alert.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.toast-alert.dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}

.tableHVVP .grid-view {
    width: 100%;
}

.tableHVVP .grid-view .grid-pagination {
    display: none;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    height: 16px;
    width: 18px;
    padding: 2px;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7";
}

.custom-datepicker {
    font-size: 0.875rem;
    height: 31px;
}

.grid-selected .custom-checkbox .custom-control-label {
    position: unset !important;
}

.thongketongquan {
    background-color: #f1f1f1;
}

.row-thongketongquan {
    margin-left: 30px;
}

.col-tongsobaidang {
    background-color: #ffff;
    margin-right: 15px;
    position: initial;
    border-radius: 3%;
}

/*.col-chitietthongkebaidang {
    background-color: #ffff;
    margin-left: -80px;
}*/

.row-thongkebaidang {
    padding-top: 20px;
}
///
.boxHoverColDetail {
    height: 65px;
    display: none
}

.boxRowDetail {
    position: relative;
}

.boxThongKeChungDetail {
    margin: 20px 0;
    position: relative;
}

.col-tongsobaidang:hover .boxHoverColDetail {
    display: block;
    position: absolute;
    top: -80px;
    width: 80% !important;
    left: 0;
    background-color: #c3e6cb;
}

.col-tongsobaidang:hover .boxHoverColDetailTKNDTC {
    display: block;
    position: absolute;
    bottom: 20px;
    width: 25% !important;
    left: 0;
    background-color: #c3e6cb;
    border-radius: 3px;
}

/*.col-tongsobaidang .boxHoverColDetailTinBaoToCao {
    display: block;
    position: absolute;
    left: 30%;
    width: 50% !important;
    top: 0;
    background-color: #c3e6cb;
}*/

.col-thongketruycap {
    background-color: #ffff;
    margin-right: 15px;
    position: initial;
    border-radius: 3%;
}

.col-topnguoidungtichcuc {
    background-color: #ffff;
    margin-right: 15px;
    position: initial;
    border-radius: 3%;
}

.boxHoverColDetailTKTC {
    display: none
}

.boxHoverColDetailTKTCBL {
    display: none
}

.boxHoverColDetailTKTCCH {
    display: none
}

.boxHoverColDetailTKTCTLCH {
    display: none
}

.boxHoverColDetailTKTCTLCH {
    display: none
}

.boxHoverColDetailNDTC {
    display: none
}

.boxHoverColDetailTKNDTC {
    display: none
}


.col-thongketruycap:hover .boxHoverColDetailTKTC {
    display: block;
    position: absolute;
    top: -80px;
    width: 19% !important;
    left: 20%;
    background-color: #c3e6cb;
    border-radius: 3px;
}

.col-topnguoidungtichcuc:hover .boxHoverColDetailNDTC {
    display: block;
    position: absolute;
    top: -80px;
    width: 19% !important;
    left: 25%;
    background-color: #c3e6cb;
    border-radius: 3px;
}

.col-thongketruycap:hover .boxHoverColDetailTKTCBL {
    display: block;
    position: absolute;
    top: -80px;
    width: 19% !important;
    left: 40%;
    background-color: #c3e6cb;
    border-radius: 3px;
}

.col-thongketruycap:hover .boxHoverColDetailTKTCCH {
    display: block;
    position: absolute;
    top: -80px;
    width: 19% !important;
    left: 60%;
    background-color: #c3e6cb;
    border-radius: 3px;
}

.col-thongketruycap:hover .boxHoverColDetailTKTCTLCH {
    display: block;
    position: absolute;
    top: -80px;
    width: 17% !important;
    left: 80%;
    background-color: #c3e6cb;
    border-radius: 3px;
}

.iconbaidang {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.li_FileAttach {
    word-break: break-word;
}
//Style cho TMP

label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 600;
    font-size: 15px;
}

.sidebar-mini .sidebar .nav-item .nav-link {
    padding: 5px !important;
    color: #fff !important
}


.nav-main_sideBar {
    max-height: calc(100vh - 25%);
    overflow: auto
}

.nav-main_sideBar .nav.nav-treeview {
    padding-left: 10px;
    font-family: "Roboto-Regular"
}

.nav-main_sideBar::-webkit-scrollbar {
    width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.nav-main_sideBar::-webkit-scrollbar-thumb {
    background: #888;
}

.content-wrapper .card-body {
    margin-bottom: 30px
}
/* Handle on hover */
.nav-main_sideBar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.text-sm.layout-navbar-fixed.sidebar-mini .wrapper {
    margin-top: 90px !important;
}

footer.main-footer {
    position: fixed;
    width: calc(100% - 220px);
    padding: 0 30px !important;
    left: 220px !important;
    bottom: 0;
    background-color: #0691d1;
    color: #fff;
    z-index: 9;
    border: 0px
}

footer.main-footer a {
    color: #fff
}

.nav-item.has-treeview.menu-open {
    border-radius: 5px;
    margin-top: 3px;
    margin: 6px;
}

.nav-item.has-treeview a:hover {
    background-color: rgba(38, 184, 234, 0.4);
}


.nav-item.has-treeview.menu-open .nav-link.active {
    background-color: rgba(38, 184, 234, 0.6);
}

.nav-item.has-treeview > .nav-link p {
    font-family: "Roboto-Regular"
}

.btn-outline-info, .btn.btn-outline-success {
    color: #00216E;
    border: 0;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: initial
}

.btn.btn-outline-danger {
    border: 0;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
}

.col .float-sm-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.col .float-sm-right .btn.btn-primary.btn-sm {
    display: flex;
    align-items: center;
    background-color: #ED3237;
    border: 0;
    min-width: 100px;
    justify-content: space-around
}

.col .float-sm-right .btn.btn-primary.btn-sm svg {
    margin-right: 5px
}

.col .float-sm-right .ml-2.btn.btn-success.btn-sm {
    background-color: #00216E !important;
    display: flex;
    align-items: center;
    border: 0;
    /*    min-width: 100px;*/
    justify-content: space-around;
    background-color: initial
}

.col .float-sm-right .ml-2.btn.btn-success.btn-sm svg {
    margin-right: 5px
}

.modal-dialog .modal-header {
    background: #00216E;
}

.btn.btn-outline-info:hover {
    background-color: initial;
    color: #00216E
}

.btn.btn-outline-danger:hover {
    background-color: initial;
    color: #dc3545
}

.carousel-control-prev, .carousel-control-next {
    background-color: rgba(0, 0, 0, 0.3);
    width: 20px
}

.carousel-control-next {
    right: -12px;
    margin-top: 30px;
}

.carousel-control-prev {
    left: -21px;
    margin-top: 30px;
}

.carousel-indicators {
    bottom: -47px;
}

//css page admin_sidebar

.nav-sidebar .nav-item > .nav-link .right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar-mini .sidebar .nav-item .nav-icon {
    max-width: 30px;
    min-width: 30px;
    font-size: 19px;
}

td span {
    font-family: "Roboto-Regular"
}

.content-header h1, .content-header h5 {
    color: #0691D1;
    font-family: Roboto-Bold;
    text-transform: uppercase;
    font-size: 18px !important
}

.react-datepicker-wrapper {
    width: 100%;
}

.slick-slide {
    padding: 5px;
}

.slick-slider {
    margin: 10px !important;
}

.tknguoidungtichcuc {
    height: 350px;
}

.slick-slide .tknguoidungtichcuc {
    background-color: #ffffff;
    border-radius: 3px;
    padding: 15px;
}

.iconstar {
    color: yellow;
    margin-bottom: 3px;
}

.main-sidebar {
    left: -2px;
    top: -1px !important;
    width: 240px
}
//code resqonsive mobile admin page
@media (max-width: 767.98px) {
    .titleNavbar {
        display: none
    }

    .navbar-expand .navbar-nav {
        align-items: center
    }

    .navbar-expand .navbar-nav.ml-auto {
        flex-direction: column-reverse
    }

    .navbar-expand .navbar-nav.ml-auto .nav-item.dropdown {
        margin-top: 10px
    }

    .content-wrapper .card-body {
        margin-bottom: 0px
    }

    .navbar-expand .nav-center {
        width: 100%;
        margin-left: 20px;
    }

    body:not(.sidebar-mini-md) .content-wrapper {
        margin-left: 0 !important
    }

    body #root .wrapper .main-footer {
        position: relative;
        left: 0 !important;
        width: 100%;
        margin-bottom: 10% !important;
        padding: 10px !important
    }

    .grid-view-action-bar.row .float-sm-right {
        margin-top: 10px !important
    }

    .table-bordered thead th {
        min-width: 170px
    }

    .btn-filter-advance.input-group-append button {
        width: 130px;
        margin-left: 10px;
        border-radius: 5px;
    }

    .sidebar.scrollbar.scrollbar-dark {
        background-size: 100% 140% !important;
    }
}

.boxthongketruycap {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.danhgianguoidunghethong {
    display: flex;
    //  border: 1px solid #ddd;
    margin-bottom: 15px;
}

.contentThongKeDanhGia {
    margin-bottom: 50px;
}

.saotrungbinh {
    font-size: 30px;
    font-weight: bold;
}

.tinhnang {
    color: rgb(56,83,164);
}

.tocdo {
    color: rgb(105,189,69);
}

.noidung {
    color: rgb(243,236,25);
}

.dotienloi {
    color: rgb(185,81,159);
}

.giatrithongtin {
    color: rgb(237,31,36);
}

.dophuhop {
    color: rgb(153,51,204);
}

.xephangdanhgia {
    margin-top: 30px;
    text-align: center;
}
.react-dropdown-tree-select .dropdown .dropdown-content ul{
    max-height:300px;
    overflow-y:auto;
}

.list-attachment li.li_FileAttach {
    display: inline-block;
    padding: 5px;
    position: relative;
}
.list-attachment li.li_FileAttach img.imgage-item {
    width: 108px;
    height: 100px;
}
.table thead th {
    vertical-align: middle;
}
.slick-prev:before, .slick-next:before{
    color:#000000 !important;
}
.card-header{
    font-weight:600;
}
.react-dropdown-tree-select .dropdown .dropdown-content {
    z-index: 99;
}

//attach image



.list-attachment li.li_FileAttach {
    display: inline-block;
    padding: 5px;
    position: relative;
}

.list-attachment li.li_FileAttach img.imgage-item {
    width: 108px;
    height: 100px;
}

.list-attachment li.li_FileAttach a.act-delete-img {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(178, 31, 45, 0.7);
    padding: 0px 5px;
    border-radius: 50%;
    width: 21px;
    height: 23px;
}

.div-tags .cNrjqJ {
    background: none;
    box-shadow: none;
    width: 100%;
    position: relative;
    font-size: 12px;
    padding: 0px;
}

.div-tags input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}
//style full size ckediter
 .cke_reset {
    width: 100% !important;
}

 //style check content noi dung nhậy cảm
.activeitem {
    color: red;
}
//style check content đã đọc
.readeditem {
    font-weight: bold;
}
//màu đánh dấu
.mark-color {
    background: yellow !important;
}
.font-16 {
    font-size: 16px;
}
.row-config-system {
    padding: 5px;
    margin-left: 7px;
    background-color: #ccc;
    margin-top: 10px;
}

.text-pre-wrap {
    white-space: pre-wrap !important;
}